import React from "react";
import MinnHeader from "./MinnHeader";
import MinnMain from "./MinnMain";

const Minneapolis = () => {
  return (
    <>
      <MinnHeader />
      <MinnMain />
    </>
  );
};

export default Minneapolis;
