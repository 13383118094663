import React from "react";
import StpHeader from "./StpHeader";
import StpMain from "./StpMain";

const StPaul = () => {
  return (
    <>
      <StpHeader />
      <StpMain />
    </>
  );
};

export default StPaul;
