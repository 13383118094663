import React from "react";

import AboutMain from "./AboutMain";
import "./About.css";
const About = () => {
  return (
    <>
      <AboutMain />
    </>
  );
};

export default About;
